import type { EventBannerType } from '@/types/Contentful/EventBanner'

export const useIsEventBannerVisible = (
  eventBanner?: EventBannerType | null,
): eventBanner is EventBannerType => {
  const now = new Date()
  const vwoExperimentKey = eventBanner?.vwoExperimentKey || ''
  const startDate = eventBanner?.startDate
  const endDate = eventBanner?.endDate
  const experimentResult = false
  // If there is no eventBanner, return false
  if (!eventBanner) return false
  // If the experimentResult is false and vwoExperimentKey is defined, return false
  if (!experimentResult && !!vwoExperimentKey) return false
  // If the current date is before the start date, return false
  if (startDate && now < startDate) return false
  // If the current date is after the end date, return false
  if (endDate && now > endDate) return false
  // Otherwise
  return true
}
